/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */

/*
NOTE: You most likely want to use api-client.js or trusty-api-client.js. 
This file sets the configuration foundation and those files inherit from this 
one to give you the correct base url for requests. If you have endpoints scoped
to their own namespace, create a new client instance.
*/

import axios from 'axios';
import { PAYMENT_REQUIRED } from 'constants/appEvents';
import EventEmitter from 'helpers/event-emitter';

function objectWithMessagesAndResponse(messages, response) {
  return {
    messages,
    response,
  };
}

export default class ApiClient {
  constructor({ baseURL }) {
    this.client = axios.create({
      baseURL,
      timeout: process.env.NODE_ENV === 'development' ? 0 : 35000,
      withCredentials: true,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    });

    this.controller = new AbortController();

    this.client.interceptors.request.use(async (config) => {
      // eslint-disable-next-line no-param-reassign
      config.signal = this.controller.signal;
      return config;
    });
  }

  _formatError = (error) => {
    const response = error.response;
    if (!response) {
      return objectWithMessagesAndResponse(error.message, response);
    }
    const { headers } = response;
    if (response.status === 402) {
      // Check if company needs to change from iTunes to direct payment company
      if (headers['x-alternative-payment-required']) {
        return objectWithMessagesAndResponse(response.data.messages, response);
      }
      EventEmitter.trigger(PAYMENT_REQUIRED);
    } else if (response.status === 401) {
      location.assign('/users/sign_in');
    } else if (
      response.data === null ||
      Object.keys(response.data).length === 0
    ) {
      // General failure, request probably didn't even fire
      return objectWithMessagesAndResponse(error.message, response);
    } else {
      return objectWithMessagesAndResponse(response.data.messages, response);
    }
  };

  get(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.client
        .get(url, { params }, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(this._formatError(e));
        });
    });
  }

  post(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.client
        .post(url, params, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(this._formatError(e));
        });
    });
  }

  put(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.client
        .put(url, params, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(this._formatError(e));
        });
    });
  }

  delete(url, params = {}, config = {}) {
    return new Promise((resolve, reject) => {
      this.client
        .delete(url, params, config)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(this._formatError(e));
        });
    });
  }

  handleCancelRequest = () => {
    this.controller.abort();
    this.controller = new AbortController();
  };
}
