class EventEmitter {
  constructor() {
    this.listeners = {};
  }

  _listenersForEvent = (event) => {
    return this.listeners[event] || [];
  };

  addListener = (event, func) => {
    this.listeners = {
      ...this.listeners,
      [event]: [...this._listenersForEvent(event), func],
    };
  };

  removeListener = (event, func) => {
    this.listeners = {
      ...this.listeners,
      [event]: this._listenersForEvent(event).filter(
        (listener) => listener !== func,
      ),
    };
  };

  trigger = (event, args) => {
    this._listenersForEvent(event).forEach((listener) => {
      listener(args);
    });
  };
}

const emitter = new EventEmitter();

export default emitter;
